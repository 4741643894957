import { DeleteTwoTone, EyeOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { selectCurrencyFormat } from 'app/commonRedux/appSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ReasonForm } from 'components/Forms';
import ITVTable from 'components/ITVTable';
import MenuAction from 'components/MenuAction';
import { YYYY_MM_DD } from 'constants/commons';
import { selectEmployee, setCheckRow } from 'features/employee/employeeSlice';
import {
  JobStatus,
  isApproveOffer,
  isInterviewer,
} from 'features/recruitment/jobs/components/types';
import {
  deleteRecruitmentCandidate,
  getCandidateManagement,
  getCandidateManagementInterviewer,
  sendOfferApproverStatus,
} from 'features/recruitment/recruitmentAction';
import { actions, selectRecruitmentData } from 'features/recruitment/recruitmentSlice';
import moment from 'moment';
import numeral from 'numeral';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Reason } from 'types';
import { getUser } from 'utils/auth';

enum HiringProcessStatusCandidate {
  APPLICATION_RECEIVED = 'Application Received',
  SHORTLISTED = 'Shortlisted',
  IN_PROGRESS = 'In Progress',
  JOB_OFFER = 'Job Offer',
  OFFER_ACCEPTED = 'Offer Accepted',
  OFFER_REJECTED = 'Offer Rejected',
  CLOSED = 'Closed',
}

const CandidatesList = ({
  // data: selectedData,
  setData: setSelectedData,
  setCurrentPage,
  currentPage,
  setSizePage,
  sizePage,
  callBack,
  status,
  filter,
}: any) => {
  const { t } = useTranslation(['recruitment', 'action', 'timesheet']);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const recruitment = useAppSelector(selectRecruitmentData);
  const candidateManagement = recruitment?.candidateManagement?.result;
  const loadingCandidateManagement = recruitment?.loadingCandidateManagement;
  const currencyFormat = useAppSelector(selectCurrencyFormat);
  const [data, setData] = useState([]);
  const selectRowKey = useAppSelector(selectEmployee).selectRowKey;
  const { resetcandidateManagement } = actions;
  const statusesToCheck = [
    HiringProcessStatusCandidate.JOB_OFFER,
    HiringProcessStatusCandidate.OFFER_ACCEPTED,
    HiringProcessStatusCandidate.OFFER_REJECTED,
  ];
  const isStatusIncluded = statusesToCheck.some((statusToCheck) => status?.includes(statusToCheck));
  const havePermissionToShowColumn = getUser()?.permissions?.some(
    (i) => i === 'RECRUIT_JOB_OFFER' || i === 'RECRUIT_APPROVE_OFFER',
  );

  useEffect(() => {
    dispatch(resetcandidateManagement({}));
    setSizePage(10);
    setCurrentPage(1);
  }, [status]);

  useEffect(() => {
    setSelectedData(selectRowKey);
  }, [selectRowKey]);

  useEffect(() => {
    const newData = candidateManagement?.map((item, index) => ({
      ...item,
      prevId: item?.id,
      id: item?.jobCandidateId,
      specialId: item?.id + '-' + item?.jobCandidateId,
    }));
    setData(newData);
  }, [recruitment]);

  const menu = [
    {
      name: t('timesheet:View_Detail'),
      icon: <EyeOutlined style={{ color: '#1890FF' }} />,
      handleClick: (value: any) => {
        history.push(`/recruitment/candidates/detail/${value?.prevId}/${value?.jobCandidateId}`);
      },
    },
    ...(isApproveOffer()
      ? [
          {
            name: t('timesheet:approve'),
            handleClick: (value: any) => {
              let reason = '';
              Modal.confirm({
                title: <div style={{ fontWeight: 'bold' }}>{t('recruitment:reason')}</div>,
                content: (
                  <>
                    <ReasonForm
                      onSubmit={(payload: Reason) => {
                        if (!payload.reason) return;
                        reason = payload.reason;
                      }}
                    />
                  </>
                ),
                // okButtonProps: { loading: isLoading },
                okText: t('action:submit'),
                cancelText: t('action:cancel'),
                onOk: () => {
                  dispatch(
                    sendOfferApproverStatus({
                      id: value?.jobCandidateId,
                      body: {
                        offerStatus: 'Approved',
                        reason,
                      },
                      callBack: () => {
                        callBack && callBack();
                      },
                    }),
                  );
                },
                onCancel: () => {},
              });
            },
          },
          {
            name: t('timesheet:Reject'),
            handleClick: (value: any) => {
              let reason = '';
              Modal.confirm({
                title: <div style={{ fontWeight: 'bold' }}>{'Reason'}</div>,
                content: (
                  <>
                    <ReasonForm
                      onSubmit={(payload: Reason) => {
                        if (!payload.reason) return;
                        reason = payload.reason;
                      }}
                    />
                  </>
                ),
                // okButtonProps: { loading: isLoading },
                okText: t('action:submit'),
                cancelText: t('action:cancel'),
                onOk: () => {
                  dispatch(
                    sendOfferApproverStatus({
                      id: value?.jobCandidateId,
                      body: {
                        offerStatus: 'Rejected',
                        reason,
                      },
                      callBack: () => {
                        callBack && callBack();
                      },
                    }),
                  );
                },
                onCancel: () => {},
              });
            },
          },
        ]
      : [
          {
            name: t('action:delete'),
            type: 'delete',
            icon: <DeleteTwoTone twoToneColor="#B7B7B7" />,
            handleClick: async (value: any, key: any) => {
              dispatch(
                deleteRecruitmentCandidate({
                  id: value?.prevId,
                  callBack: () => {
                    callBack && callBack();
                  },
                }),
              );
            },
          },
        ]),
  ];
  const formatInterviewDateTime = (dateTimeString: string) => {
    const [date, timeRange] = dateTimeString.split(' ');
    const [startTime, endTime] = timeRange.split('-');

    const formattedDate = moment(date).format('YYYY-MM-DD');
    const formattedStartTime = moment(startTime, 'HH:mm:ss').format('h:mmA');
    const formattedEndTime = moment(endTime, 'HH:mm:ss').format('h:mmA');

    return (
      <div>
        {formattedDate.toLowerCase()}
        <br />
        {formattedStartTime.toLowerCase()}-{formattedEndTime.toLowerCase()}
      </div>
    );
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 40,
      minWidth: 40,
      align: 'center',
      render: (_: any, __: any, index: any) => {
        const trueIndex = index + sizePage * (currentPage - 1);
        return <div>{trueIndex + 1}</div>;
      },
    },
    {
      title: t('recruitment:candidate_name'),
      dataIndex: 'candidateName',
      key: 'candidateName',
      width: 150,
      minWidth: 150,
    },
    {
      title: t('recruitment:email'),
      dataIndex: 'email',
      key: 'email',
      width: 150,
      minWidth: 150,
    },
    {
      title: t('recruitment:phone_number'),
      dataIndex: 'phone',
      key: 'phone',
      width: 80,
      minWidth: 80,
    },
    {
      title: t('recruitment:applied_position'),
      dataIndex: 'positionName',
      key: 'positionName',
      width: 100,
      minWidth: 100,
    },
    {
      title: t('recruitment:applied_date'),
      dataIndex: 'appliedDate',
      key: 'appliedDate',
      width: 80,
      minWidth: 80,
      align: 'center',
      render: (value: any, _: any, __: any) => {
        return value ? moment(value).format(YYYY_MM_DD) : '';
      },
    },
    ...(!status && (!isInterviewer() || isApproveOffer())
      ? [
          {
            title: t('recruitment:candidate_result'),
            dataIndex: 'result',
            key: 'result',
            width: 80,
            minWidth: 80,
          },
        ]
      : []),
    ...(status == HiringProcessStatusCandidate.IN_PROGRESS
      ? [
          {
            title: t('recruitment:interview_date_time'),
            dataIndex: 'interviewDateAndTime',
            key: 'interviewDateAndTime',
            width: 110,
            minWidth: 110,
            align: 'center',
            render: (value: any, _: any, __: any) => {
              return value ? formatInterviewDateTime(value) : '';
            },
          },
          {
            title: t('recruitment:location'),
            dataIndex: 'interviewLocation',
            key: 'interviewLocation',
            width: 80,
            minWidth: 80,
          },
          {
            title: t('recruitment:candidateInterviewStatus'),
            dataIndex: 'candidateInterviewStatus',
            key: 'candidateInterviewStatus',
            width: 80,
            minWidth: 80,
          },
          {
            title: t('recruitment:interviewerStatus'),
            dataIndex: 'interviewerStatus',
            key: 'interviewerStatus',
            width: 80,
            minWidth: 80,
          },
        ]
      : []),
    ...(isStatusIncluded && havePermissionToShowColumn
      ? [
          {
            title: t('recruitment:expected_start_date'),
            dataIndex: 'expectedStartDate',
            key: 'expectedStartDate',
            width: 80,
            minWidth: 80,
            align: 'center',
            render: (value: any, _: any, __: any) => {
              return value ? moment(value).format(YYYY_MM_DD) : '';
            },
          },
          {
            title: t('recruitment:salary_offer'),
            dataIndex: 'salaryOffer',
            key: 'salaryOffer',
            width: 80,
            minWidth: 80,
            render: (value: any, _: any, __: any) => {
              return value ? numeral(value).format(currencyFormat) : '';
            },
          },
          {
            title: t('timesheet:status'),
            dataIndex: 'offerStatus',
            key: 'offerStatus',
            width: 80,
            minWidth: 80,
          },
        ]
      : status?.includes(HiringProcessStatusCandidate.IN_PROGRESS) || !status
      ? [
          {
            title: t('recruitment:stage_name'),
            dataIndex: 'stageName',
            key: 'stageName',
            width: 80,
            minWidth: 80,
          },
        ]
      : []),
    ...(status === HiringProcessStatusCandidate.OFFER_REJECTED
      ? [
          {
            title: t('recruitment:rejected_by'),
            dataIndex: 'offerBy',
            key: 'offerBy',
            width: 80,
            minWidth: 80,
          },
        ]
      : []),
    {
      title: t('recruitment:created_date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 80,
      minWidth: 80,
      align: 'center',
      render: (value: any, _: any, __: any) => {
        return value ? moment(value).format(YYYY_MM_DD) : '';
      },
    },
    {
      title: t('action:action'),
      key: 'action',
      dataIndex: 'action',
      width: 50,
      minWidth: 50,
      align: 'center',
      fixed: 'right',
      render: (text: any, record: any) => {
        const filteredMenu =
          JobStatus.WAITING_FOR_APPROVAL !== record?.offerStatus
            ? menu.filter(
                (m) => m.name !== t('timesheet:approve') && m.name !== t('timesheet:Reject'),
              )
            : menu;
        return <MenuAction menu={filteredMenu} data={record} />;
      },
    },
  ];
  return (
    <ITVTable
      setOffset={setCurrentPage}
      offset={currentPage}
      setLimit={setSizePage}
      loading={loadingCandidateManagement}
      limit={sizePage}
      columns={columns}
      isRowSelect={
        status === HiringProcessStatusCandidate.JOB_OFFER ||
        status === HiringProcessStatusCandidate.IN_PROGRESS
      }
      totalResult={recruitment?.candidateManagement?.totalResult || undefined}
      data={data}
      setSelectedUser={setSelectedData}
      rowKey={['jobCandidateId']} //specialId
      width={1500}
    />
  );
};

export default CandidatesList;
