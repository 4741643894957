import { Tabs } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import PageHeader from 'components/PageHeader';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import EmployeeColumn from './components/EmployeeColumn';
import Summary from './components/Summary/Summary';
import AppliedJobs from './components/AppliedJobs/AppliedJobs';
import Notes from 'features/recruitment/jobs/detail/components/notes/Notes';
import CandidateEval from './components/CandidateEval/CandidateEval';
import { useHistory, useParams } from 'react-router-dom';
import ButtonBack from 'components/ButtonBack';
import CustomButton from 'styles/buttonStyled';
import {
  createCandidate,
  getDetailCandidate,
  updateCandidate,
  updateCandidateNoteInterviewer,
  updateDetailCandidateEval,
} from 'features/recruitment/recruitmentAction';
import { actions, selectRecruitmentData } from 'features/recruitment/recruitmentSlice';
import { isInterviewer } from 'features/recruitment/jobs/components/types';
import { getUser } from 'utils/auth';
import { useTranslation } from 'react-i18next';
import QuestionCandidate from './components/QuestionPool/QuestionCadidate';

const CandidateDetail = () => {
  const { t } = useTranslation(['recruitment', 'action', 'menu']);
  const dispatch = useAppDispatch();
  const { id, jobId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const menuStore = useAppSelector(selectConfiguration);
  const dataScreenManage = menuStore?.dataScreenManage?.result;
  const [titleHeader, setTitleHeader] = useState('');
  const [data, setData] = useState({});
  const [dataOri, setDataOri] = useState({});
  const detailCandidate = useAppSelector(selectRecruitmentData)?.detailCandidate;
  const { resetdetailCandidate } = actions;
  const [activeTab, setActiveTab] = useState('summary');

  const handleChange = (formData: any) => {
    let cvData = {};
    if (activeTab === 'candidates') {
      if (formData?.file?.data) {
        cvData = {
          ...formData?.file?.data,
        };
      }
      setData((prevData) => ({
        ...prevData,
        ...cvData,
        candidateEvaluation: formData?.candidateEvaluation?.map((item, idx) => ({
          candidateId: prevData.id,
          candidateRequisitionId: item?.candidateRequisitionId,
          positionName: prevData.candidateEvaluation[idx]?.positionName || item?.positionName,
          isApplied: prevData.candidateEvaluation[idx]?.isApplied,
          overallScore: prevData.candidateEvaluation[idx]?.overallScore,
          candidateEvaluation: [
            {
              candidateProcessId: item.candidateProcessId,
              candidateEvaluationId: item.candidateEvaluationId,
              stageName: item.stageName,
              evaluation: item.evaluation.map((evalua) => {
                const prevEvaluation =
                  prevData.candidateEvaluation[0]?.candidateEvaluation[0]?.evaluation.find(
                    (e) => e.evaluatedById === evalua.evaluatedById,
                  );
                return {
                  data: evalua.data.map((d) => ({
                    name: d.name,
                    score: d.score,
                    comment: d.comment,
                    maximumScore: d.maximumScore,
                    minimumScore: d.minimumScore,
                  })),
                  overallScore: evalua.overallScore,
                  evaluatedById: evalua.evaluatedById,
                  evaluatedByName: evalua.evaluatedByName,
                  isEdit: evalua.isEdit,
                };
              }),
            },
          ],
        })),
      }));
    } else {
      if (formData?.file?.data) {
        cvData = {
          ...formData?.file?.data,
          yearExperience: formData?.file?.data?.yearExperience + '',
        };
      }
      setData((prevData) => ({ ...prevData, ...cvData, ...formData }));
    }
  };

  const callApiHaveId = () => {
    dispatch(getDetailCandidate({ id, jobCandidateId: jobId !== 'null' ? jobId : undefined }));
  };

  useEffect(() => {
    if (id !== 'draft') {
      callApiHaveId();
    }
    return () => {
      setData({});
      setDataOri({});
      dispatch(resetdetailCandidate({}));
    };
  }, [dispatch, id, resetdetailCandidate]);

  useEffect(() => {
    const newData = {
      ...detailCandidate,
      ...detailCandidate?.personalInfor,
      ...detailCandidate?.salary,
      linkProfile: detailCandidate?.linkProfile,
      jobNotes: detailCandidate?.candidateNote,
      certifications: detailCandidate?.personalInfor?.certifications,
      skills: detailCandidate?.personalInfor?.skills?.map((i) => ({
        ...i,
        rate: typeof i?.rate === 'string' ? Number(i?.rate?.split('/')?.[0]) : i?.rate,
      })),
      jobs: detailCandidate?.jobApplied?.map((i) => ({ ...i, jobTemplate: { ...i } })),
    };
    setData(newData);
    setDataOri(newData);
  }, [detailCandidate]);

  useEffect(() => {
    const temp = dataScreenManage
      ?.filter((item) => location.pathname === item.path)
      ?.map((item) => item.nameScreen);

    setTitleHeader(temp ? temp[0] : '');
  }, [dataScreenManage, location.pathname]);

  const tabs = [
    {
      tabName: t('recruitment:summary'),
      key: 'summary',
      component: <Summary handleChange={handleChange} data={data} />,
    },
    {
      tabName: t('recruitment:applied_jobs'),
      key: 'hirring',
      component: <AppliedJobs handleChange={handleChange} data={data} />,
    },
    {
      tabName: t('recruitment:candidate_evaluation'),
      key: 'candidates',
      component: <CandidateEval data={data} handleChange={handleChange} />,
    },
    {
      tabName: t('recruitment:notes_and_feedback'),
      key: 'notes',
      component: <Notes handleChange={handleChange} data={data} />,
    },
    {
      tabName: t('menu:question-pool'),
      key: 'question',
      component: <QuestionCandidate data={data} />,
    },
  ];

  function mergeEvaluations(candidateEvaluation) {
    return candidateEvaluation.map((evaluationItem) => {
      const { candidateProcessId, candidateEvaluationId, stageName, evaluation } = evaluationItem;

      // Gộp các evaluations theo evaluatedById
      const mergedEvaluations = evaluation.reduce((acc, curr) => {
        const { evaluatedById, evaluatedByName, data, overallScore, isEdit } = curr;
        const existingEvaluation = acc.find((ev) => ev.evaluatedById === evaluatedById);

        if (existingEvaluation) {
          existingEvaluation.data.push(...data);
        } else {
          acc.push({ evaluatedById, evaluatedByName, data: [...data], overallScore, isEdit });
        }

        return acc;
      }, []);

      return {
        candidateProcessId,
        candidateEvaluationId,
        stageName,
        evaluation: mergedEvaluations,
      };
    });
  }

  const handleSave = () => {
    if (activeTab === 'candidates') {
      const mergedData = data.candidateEvaluation.map((candidate) => ({
        candidateId: candidate.candidateId,
        candidateRequisitionId: candidate.candidateRequisitionId,
        candidateEvaluation: mergeEvaluations(candidate.candidateEvaluation),
      }));
      dispatch(
        updateDetailCandidateEval({
          body: mergedData || [],
          callBack: () => {
            if (id !== 'draft') {
              callApiHaveId();
            }
          },
        }),
      );
      return;
    }

    const body = {
      ...data,
      candidateNotes: data?.jobNotes || [],
      skills: data?.skills?.map((i) => ({ ...i, rate: `${i.rate}/10` })),
    };

    if (id === 'draft') {
      dispatch(
        createCandidate({
          body,
          callBack: () => {
            history.push('/recruitment/candidates');
          },
        }),
      );
    } else {
      if (isInterviewer()) {
        dispatch(
          updateCandidateNoteInterviewer({
            id,
            body,
          }),
        );
      } else
        dispatch(
          updateCandidate({
            id,
            body,
          }),
        );
    }
  };

  const showButtons =
    (activeTab !== 'candidates'
      ? !isInterviewer()
      : isInterviewer() && activeTab === 'candidates') ||
    activeTab === 'notes' ||
    (getUser()?.permissions?.includes('RECRUIT_CANDIDATE_INTERVIEW') && activeTab === 'hirring') ||
    activeTab === 'candidates';

  const isFormValid = () => {
    return (
      // data?.currentCompany &&
      data?.currentJob && data?.candidateName && data?.email && data?.phone && data?.yearExperience
    );
  };

  return (
    <>
      <ButtonBack handleBack={() => history.goBack()} />
      <PageHeader title={titleHeader} />
      {id !== 'draft' && (
        <EmployeeColumn
          avatar={data?.candidateName}
          fullName={data?.candidateName}
          email={data?.email}
          status={data?.jobCandidateStatus}
        />
      )}
      <Tabs onChange={(key) => setActiveTab(key)}>
        {(id === 'draft'
          ? tabs.filter((tab) => tab.key !== 'candidates' && tab.key !== 'question')
          : tabs
        )
          .filter((tab) => !(tab.key === 'hirring' && isInterviewer()))
          .map(({ tabName, key, component }) => (
            <Tabs.TabPane tab={tabName} key={key}>
              {component}
            </Tabs.TabPane>
          ))}
      </Tabs>
      {showButtons && (
        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 8, marginBottom: '1rem' }}>
          <CustomButton
            aType="text"
            onClick={() => {
              setData(dataOri);
            }}
          >
            {t('action:cancel')}
          </CustomButton>
          <CustomButton onClick={handleSave} disabled={!isFormValid() && !isInterviewer()}>
            {t('action:save')}
          </CustomButton>
        </div>
      )}
    </>
  );
};

export default CandidateDetail;
