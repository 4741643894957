import { Input, TimePicker } from 'antd';
import Popconfirm from 'antd/es/popconfirm';
import timesheetApi from 'api/timesheetApi';
import editIcon from 'assets/icons/ic_edit_info.svg';
import { notificationToast } from 'components/notificationToast';
import { TIMEZONE_DEFAULT } from 'constants/commons';
import { Notification } from 'constants/notification';
import { EditTimeProps, EditType } from 'features/timesheet/interfaces';
import { changeEndTime, changeStartTime } from 'features/timesheet/utils/complexLogicTime';
import { PermissionType, TimeSheetStatus } from 'hrm-common/extensions/enums/personel';
import moment, { Moment } from 'moment-timezone';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import CustomButton from 'styles/buttonStyled';
import CustomInput from 'styles/inputStyled';
import { getUser } from 'utils/auth';
import * as DateUtils from 'utils/date';
import { capitalizeFirstLetter } from 'utils/text';
import {
  formatUT_YYYY_MM_DD,
  setAllNotyfiError,
  setAllNotyfiSuccess,
} from '../../contants/formatdate';
import { createAdjustTime } from '../../pages/timesheetSlice';

const { TextArea } = Input;

const HrUpdateTime = (props: EditTimeProps) => {
  if (props.editType === EditType.ADJUST) {
    return <UpdateAdjustTime {...props} />;
  }
  if (props.editType === EditType.OVERTIME) {
    return <UpdateOverTime {...props} />;
  }
  if (props.editType === EditType.LEAVE) {
    return <UpdateLeaveTime {...props} />;
  }
  return null;
};

const UpdateAdjustTime = (props: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['timesheet']);
  let startTime = props.checkinTime ? props.checkinTime : null;
  let endTime = props.checkoutTime ? props.checkoutTime : null;
  if (!startTime) startTime = endTime;
  if (!endTime) endTime = startTime;
  const isAdjustTime = !!props.currentWorkTime?.requestAdjustTime;
  const totalHours = Number(props.currentWorkTime?.requestAdjustTime?.totalHour);
  const totalHoursWorkTime = (() => {
    if (!isAdjustTime) {
      return Number(props.currentWorkTime?.totalHours);
    }
    return Number(props.currentWorkTime?.requestAdjustTime?.originalTotalHours);
  })();
  const startTimeAdjust = props.currentWorkTime?.requestAdjustTime?.startTime;
  const endTimeAdjust = props.currentWorkTime?.requestAdjustTime?.endTime;
  const originCheckIn = props.currentWorkTime?.requestAdjustTime?.originCheckin;
  const originCheckOut = props.currentWorkTime?.requestAdjustTime?.originCheckout;

  let [reason, setReason] = useState(props.reason);
  let [isEdit, setIsEdit] = useState(false);
  const timeZone = getUser().timeZone ? getUser().timeZone : TIMEZONE_DEFAULT;
  const permissions: Array<string> = getUser()?.permissions || [];

  //Set default timezone in server
  moment.tz.setDefault(timeZone);

  useEffect(() => {
    setReason(props.reason);
  }, [props.reason]);

  let [time1, setTime1] = useState(
    startTime
      ? moment(startTime).tz(timeZone)
      : moment(props.value).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
  );

  let [time2, setTime2] = useState(
    endTime
      ? moment(endTime).tz(timeZone)
      : moment(props.value).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
  );

  const handleSubmit = () => {
    if (
      time1.hour() > time2.hour() ||
      (time1.hour() === time2.hour() && time1.minute() >= time2.minute())
    ) {
      notificationToast(
        Notification.Type.Error,
        'Start time cannot higher than end time',
        Notification.Duration._3s,
      );
      return;
    }
    dispatch(
      createAdjustTime({
        workingTimeId: props.id,
        reason,
        startTime: time1.utc(),
        endTime: time2.utc(),
        status: TimeSheetStatus.APPROVED,
        userId: props.userId,
        callBack: () => {
          if (props.refreshData) {
            props.refreshData();
            props.handleVisible(false);
            props.isShow.current = false;
          }
        },
      }),
    );
  };

  const onClickApprove = async () => {
    let monthYear = moment(props.currentDay).format('YYYY-MM');
    try {
      let params = {
        searchWorkingTime: {
          monthYear: monthYear,
          userId: props.userId,
        },
        typeRequest: EditType.ADJUST,
        status: TimeSheetStatus.APPROVED,
        select: {
          key: 'id',
          value: [props.id],
        },
      };
      const respons = await timesheetApi.apiCreateApprovalRequest(params);
      // @ts-ignore
      if (respons.statusCode === 200) {
        props.refreshData();
        setAllNotyfiSuccess(t('Approve_success'));
      } else {
        setAllNotyfiError(t('Approve_Failure'));
      }
    } catch (e) {
      console.log('error');
    }
  };

  const onClickReject = async () => {
    let monthYear = moment(props.currentDay).format('YYYY-MM');
    try {
      let params = {
        searchWorkingTime: {
          monthYear: monthYear,
          userId: props.userId,
        },
        typeRequest: EditType.ADJUST,
        status: TimeSheetStatus.REJECTED,
        select: {
          key: 'id',
          value: [props.id],
        },
      };
      const respons = await timesheetApi.apiCreateApprovalRequest(params);
      // @ts-ignore
      if (respons.statusCode === 200) {
        props.refreshData();
        setAllNotyfiSuccess(t('Adjust_success'));
      } else {
        setAllNotyfiError(t('Adjust_Failure'));
      }
    } catch (e) {
      console.log('error');
    }
  };

  const CancelSubmit = async () => {
    setReason(props.reason);
    setTime1(
      startTime
        ? moment(startTime)
        : moment(props.value).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
    );
    setTime2(
      endTime
        ? moment(endTime)
        : moment(props.value).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
    );
    setIsEdit(false);
    props.handleVisible(false);
    props.refreshData && props.refreshData();
  };

  const onChangeStart_time = (time: Moment | null) => {
    const optionTime = { timeSelect: time, currentDay: props.currentDay, timeEnd: time2 };
    changeStartTime(optionTime, setTime1, setTime2);
  };

  const onChangeEnd_time = (time: Moment | null) => {
    const optionTime = { timeSelect: time, currentDay: props.currentDay, timeStart: time1 };
    changeEndTime(optionTime, setTime1, setTime2);
  };

  return (
    <Container>
      <DateValue>
        <span>{props.currentDay && moment(props.currentDay).format('dddd, DD MMMM YYYY')}</span>
        {props.status ? (
          <StatusTag type={props?.status}>{capitalizeFirstLetter(props?.status)}</StatusTag>
        ) : (
          !isEdit &&
          permissions.some(
            (permission: string) =>
              permission === PermissionType.TIMESHEET_ADJUST_TIMECLOCK_FOR_EMPLOYEE,
          ) && (
            <img
              src={editIcon}
              alt={editIcon}
              onClick={() => setIsEdit(true)}
              style={{ cursor: 'pointer' }}
            />
          )
        )}
      </DateValue>
      {!props.status ? (
        <>
          <WorkingTime>
            <label>{t('Working_time')} *</label>
            <div>
              <TimePicker
                value={time1}
                onChange={onChangeStart_time}
                disabled={!isEdit}
                format={'HH:mm'}
                onSelect={onChangeStart_time}
                popupClassName="hidden__footer"
                disabledMinutes={(selectedHour: number) => {
                  if (
                    selectedHour >= 0 &&
                    DateUtils.checkIsFirstOrLastOfTime('lastOfTime', selectedHour)
                  )
                    return [59];
                  return [];
                }}
              />
              <span> - </span>
              <TimePicker
                value={time2}
                onChange={onChangeEnd_time}
                disabled={!isEdit}
                format={'HH:mm'}
                onSelect={onChangeEnd_time}
                popupClassName="hidden__footer"
                disabledMinutes={(selectedHour: number) => {
                  if (
                    selectedHour >= 0 &&
                    DateUtils.checkIsFirstOrLastOfTime('firstOfTime', selectedHour)
                  )
                    return [0];
                  return [];
                }}
              />
            </div>
          </WorkingTime>
          <WorkingDuration>
            <label>{t('Working_duration')}</label>
            <p>
              {time1 > time2
                ? '0:00'
                : props.totalHours
                ? props.totalHours.toFixed(2)
                : Number(0).toFixed(2)}{' '}
              {t('hours')}
            </p>
          </WorkingDuration>
        </>
      ) : (
        <ReviewInfo>
          <ReviewInfoLeft>
            <div className="title">{t('Current_information')}</div>
            <div style={{ padding: '5px 15px' }}>
              <WorkingTime>
                <label>{t('Working_time')}</label>
                <p>{`${DateUtils.formatHour(originCheckIn)} - ${DateUtils.formatHour(
                  originCheckOut,
                )}`}</p>
              </WorkingTime>
              <WorkingDuration>
                <label>{t('Working_duration')}</label>
                <p>
                  {totalHoursWorkTime.toFixed(2)} {t('hours')}
                </p>
              </WorkingDuration>
            </div>
          </ReviewInfoLeft>
          <ReviewInfoRight>
            <div className="title">{t('Adjusted_information')}</div>
            <div style={{ padding: '5px 15px' }}>
              <WorkingTime>
                <label>{t('Working_time')}</label>
                <p>{`${DateUtils.formatHour(startTimeAdjust)} - ${DateUtils.formatHour(
                  endTimeAdjust,
                )}`}</p>
              </WorkingTime>
              <WorkingDuration>
                <label>{t('Working_duration')}</label>
                <p>
                  {totalHours.toFixed(2)} {t('hours')}
                </p>
              </WorkingDuration>
            </div>
          </ReviewInfoRight>
        </ReviewInfo>
      )}

      {(isEdit || props.status) && (
        <Reason>
          <label>{t('Reason_for_adjust')} *</label>
          <TextArea
            rows={3}
            style={{ borderRadius: 5 }}
            value={reason}
            onChange={(e: any) => setReason(e.target.value)}
            disabled={props.status}
          />
        </Reason>
      )}
      {isEdit && !props.status && (
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
          <CustomButton onClick={CancelSubmit} aType="text">
            {t('Cancel')}
          </CustomButton>
          <CustomButton
            type="primary"
            disabled={!reason || totalHours === 0}
            onClick={handleSubmit}
          >
            {t('Send_request')}
          </CustomButton>
        </div>
      )}
      {props.status === TimeSheetStatus.PENDING && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
          <Popconfirm
            title={t('Are_you_Reject_request')}
            onConfirm={() => onClickReject()}
            // onCancel={cancel}
            okText={t('Yes')}
            cancelText={t('No')}
          >
            <CustomButton
              type="text"
              style={{
                borderWidth: 0.5,
                borderStyle: 'solid',
                borderColor: '#c4c1c1',
                marginRight: 20,
              }}
            >
              {t('Reject')}
            </CustomButton>
          </Popconfirm>
          <Popconfirm
            title={t('Are_you_Approve_request')}
            onConfirm={() => onClickApprove()}
            // onCancel={cancel}
            okText={t('Yes')}
            cancelText={t('No')}
          >
            <CustomButton type="primary">{t('Approver')}</CustomButton>
          </Popconfirm>
        </div>
      )}
    </Container>
  );
};

export const UpdateOverTime = (props: any) => {
  const { t } = useTranslation(['timesheet', 'overtime']);

  let startTime = props.startTime
    ? moment(props.startTime)
    : props.checkinTime
    ? moment(props.checkinTime)
    : null;
  let endTime = props.endTime
    ? moment(props.endTime)
    : props.checkoutTime
    ? moment(props.checkoutTime)
    : null;
  if (!startTime) startTime = endTime;
  if (!endTime) endTime = startTime;
  const [reason] = useState(props.reason);
  const [time1] = useState(startTime);
  const [time2] = useState(endTime);
  const [totalHours, setTotalHours] = useState(0);

  useEffect(() => {
    if (time1 && time2) {
      setTotalHours(time2.diff(time1, 'minutes') / 60);
    }
  }, [time1, time2]);

  return (
    <Container>
      <DateValue>
        <span>{props.currentDay && moment(props.currentDay).format('dddd, DD MMMM YYYY')}</span>
        {props.status && (
          <StatusTag type={props?.status}>{capitalizeFirstLetter(props?.status)}</StatusTag>
        )}
      </DateValue>
      <Item>
        <label>{t('overtime:project')}</label>
        <p>{`${props?.projectCode || ''}`}</p>
      </Item>
      <Item>
        <label>{t('title')}</label>
        <CustomInput disabled={true} value={props.title || null} />
      </Item>
      <Item>
        <label>{t('Time_of_Overtime')}</label>
        <p>{`${props.startTime.slice(11, 16)} - ${props.endTime.slice(11, 16)}`}</p>
      </Item>
      <Item>
        <label>{t('Overtime_duration')}</label>
        <p>{totalHours.toFixed(2)} hours</p>
      </Item>
      <Item>
        <label>{t('Type_of_overtime')}</label>
        <CustomInput disabled={true} value={props.type || null} />
      </Item>
      <Item>
        <label>{t('Reason')}</label>
        <TextArea rows={3} style={{ borderRadius: 5 }} value={reason || null} disabled={true} />
      </Item>
      <Item>
        <label>{t('Inform_to')}</label>
        <CustomInput disabled={true} value={props.supervisorsName?.join(', ') || null} />
      </Item>
      <Item>
        <label>{t('Approver')}</label>
        <CustomInput disabled={true} value={props?.currentProjectLeaderName || null} />
      </Item>
    </Container>
  );
};

export const UpdateLeaveTime = (props: any) => {
  const { t } = useTranslation(['timesheet']);
  let startTime = props.startTime
    ? moment(props.startTime)
    : props.checkinTime
    ? moment(props.checkinTime)
    : null;
  let endTime = props.endTime
    ? moment(props.endTime)
    : props.checkoutTime
    ? moment(props.checkoutTime)
    : null;
  if (!startTime) startTime = endTime;
  if (!endTime) endTime = startTime;
  const [reason] = useState(props.reason);
  const [time1] = useState(startTime);
  const [time2] = useState(endTime);
  const onClickApprove = async () => {
    let monthYear = moment(props.currentDay).format('YYYY-MM');
    try {
      const params = {
        searchWorkingTime: {
          monthYear: monthYear,
          userId: props.userId,
        },
        typeRequest: EditType.LEAVE,
        status: TimeSheetStatus.APPROVED,
        select: {
          key: 'id',
          value: [props.id],
        },
      };
      const respons = await timesheetApi.apiCreateApprovalRequest(params);
      // @ts-ignore
      if (respons.statusCode === 200) {
        props.refreshData();
        setAllNotyfiSuccess(t('Approve_success'));
      } else {
        setAllNotyfiError(t('Approve_Failure'));
      }
    } catch (e) {
      console.log('error');
    }
    props.handleVisibleLeavePopUp(false);
    props.isShowLeave.current = false;
  };
  const onClickReject = async () => {
    let monthYear = moment(props.currentDay).format('YYYY-MM');
    try {
      const params = {
        searchWorkingTime: {
          monthYear: monthYear,
          userId: props.userId,
        },
        typeRequest: EditType.LEAVE,
        status: TimeSheetStatus.REJECTED,
        select: {
          key: 'id',
          value: [props.id],
        },
      };
      const respons = await timesheetApi.apiCreateApprovalRequest(params);
      // @ts-ignore
      if (respons.statusCode === 200) {
        props.refreshData();
        setAllNotyfiSuccess(t('Reject_successfully'));
      } else {
        setAllNotyfiError(t('Reject_Failure'));
      }
    } catch (e) {
      console.log('error');
    }
    props.handleVisibleLeavePopUp(false);
    props.isShowLeave.current = false;
  };
  return (
    <Container>
      <DateValue>
        <span>{props.currentDay && moment(props.currentDay).format('dddd, DD MMMM YYYY')}</span>
        {props.status && (
          <StatusTag type={props?.status}>{capitalizeFirstLetter(props?.status)}</StatusTag>
        )}
      </DateValue>
      <Item>
        <label>Title</label>
        <CustomInput disabled={true} value={props.title || null} />
      </Item>
      <Item style={{ display: 'flex' }}>
        <Item>
          <Item>
            <label>{t('leave_off_start_date')}</label>
            <p>{`${moment(props.startTime).format('HH:mm')} - ${formatUT_YYYY_MM_DD(time1)}`}</p>
          </Item>
          <Item>
            <label>{t('Leave_off_end_date')}</label>
            <p>{`${moment(props.endTime).format('HH:mm')} - ${formatUT_YYYY_MM_DD(time2)}`}</p>
          </Item>
        </Item>
        <Item style={{ marginLeft: 30 }}>
          <Item>
            <label>{t('Leave_off_duration')}</label>
            <p>
              {props.totalHour} {t('hours')}
            </p>
          </Item>
        </Item>
      </Item>
      {/*<Item>*/}
      {/*  <label>Time off start date</label>*/}
      {/*  <p>{`${formatHoursMinutes(props.startTime)} - ${formatUT_YYYY_MM_DD(time1)}`}</p>*/}
      {/*</Item>*/}
      {/*<Item>*/}
      {/*  <label>Time off end date</label>*/}
      {/*  <p>{`${formatHoursMinutes(props.endTime)} - ${formatUT_YYYY_MM_DD(time2)}`}</p>*/}
      {/*</Item>*/}
      <Item>
        <label>{t('Leave_off_type')}</label>
        <CustomInput disabled={true} value={`${props.type + ' (' + props.remain + ')'}`} />
      </Item>
      <Item>
        <label>{t('Reason')}</label>
        <TextArea rows={3} style={{ borderRadius: 5 }} value={reason || null} disabled={true} />
      </Item>
      <Item>
        <label>{t('Inform_to')}</label>
        <CustomInput disabled={true} value={props.supervisorsName || null} />
      </Item>
      <Item>
        <label>{t('Approver')}</label>
        <CustomInput disabled={true} value={props?.currentWorkTime?.currentManager || null} />
      </Item>
      {props.status === TimeSheetStatus.PENDING && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
          <Popconfirm
            title={t('Are_you_Reject_request')}
            onConfirm={() => onClickReject()}
            // onCancel={cancel}
            okText={t('Yes')}
            cancelText={t('No')}
          >
            <CustomButton
              type="text"
              style={{
                borderWidth: 0.5,
                borderStyle: 'solid',
                borderColor: '#c4c1c1',
                marginRight: 20,
              }}
            >
              {t('Reject')}
            </CustomButton>
          </Popconfirm>
          <Popconfirm
            title={t('Are_you_Approve_request')}
            onConfirm={() => onClickApprove()}
            // onCancel={cancel}
            okText={t('Yes')}
            cancelText={t('No')}
          >
            <CustomButton type="primary">{t('Approver')}</CustomButton>
          </Popconfirm>
        </div>
      )}
    </Container>
  );
};

export default memo(HrUpdateTime);

export const Container = styled.div``;

export const Item = styled.div`
  margin-bottom: 15px;

  label {
    font-size: 12px;
    color: var(--cl_gray500);
  }

  p {
    font-size: 16px;
    color: var(--cl_gray900);
    margin-bottom: 0;
  }
`;

export const DateValue = styled.div`
  span {
    font-size: 16px;
    color: var(--cl_info500);
    font-weight: 500;
  }

  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
`;

export const WorkingTime = styled.div`
  margin-bottom: 15px;

  label {
    font-size: 12px;
    color: var(--cl_gray500);
  }

  p {
    font-size: 16px;
    color: var(--cl_gray900);
  }
`;

export const WorkingDuration = styled.div`
  label {
    font-size: 12px;
    color: var(--cl_gray500);
  }

  p {
    font-size: 16px;
    color: var(--cl_gray900);
  }
`;

export const Reason = styled.div`
  label {
    font-size: 12px;
    color: var(--cl_gray500);
  }

  p {
    font-size: 16px;
    color: var(--cl_gray900);
  }
`;

interface StatusTagProps {
  type: string;
}

export const StatusTag = styled.div`
  border-radius: 5px;
  text-align: center;
  display: inline;
  padding: 0 5px;
  margin-left: 20px;
  ${(props: StatusTagProps) => {
    switch (props.type) {
      case 'working':
        return css``;
      case 'adjust':
        return css`
          background-color: var(--cl_info500);
        `;
      case 'approved':
        return css`
          background-color: var(--cl_success100);
        `;
      case 'pending':
        return css`
          background-color: var(--cl_warning100);
        `;
      case 'rejected':
        return css`
          background-color: var(--cl_error100);
        `;
      default:
        break;
    }
  }}
`;

export const ReviewInfo = styled.div`
  display: flex;
  border-radius: 5px;
  border: 2px solid var(--cl_gray300);
  margin-bottom: 10px;
`;

export const ReviewInfoLeft = styled.div`
  flex-grow: 1;
  border-right: 2px solid var(--cl_gray300);

  .title {
    background-color: var(--cl_gray100);
    padding: 5px 15px;
  }
`;

export const ReviewInfoRight = styled.div`
  flex-grow: 1;

  .title {
    background-color: var(--cl_gray100);
    padding: 5px 15px;
  }
`;
